import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-network-page',
  templateUrl: `./network-page.component.html`,
  encapsulation: ViewEncapsulation.None,
})
export class NetworkPageComponent {
  links = [
    { name: 'Organigramma', url: './chart' },
    { name: 'Albero', url: './summary' },
  ];
  activeLink = this.links[0].url;
}
