<fullstack-header></fullstack-header>

<ng-template header-body>
  <h1 class="tx-title-1">Network</h1>
</ng-template>

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  @for (link of links; track link) {
    <a mat-tab-link [routerLink]="link.url" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
      {{ link.name }}
    </a>
  }
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
