<div class="pt-4">
  <p-organizationChart [value]="network" [collapsible]="true" selectionMode="multiple" [(selection)]="selectedNodes" [preserveSpace]="false">
    <ng-template let-node pTemplate="default">
      <div class="text-center ">
        <div class="font-sans text-xl" [ngClass]="node.data.userObj | getRolesColor">{{ node.data.userObj?.firstName }} {{ node.data.userObj?.lastName }}</div>
        <div class="font-sans text-sm font-semibold text-gray-500">{{ node.data.userObj | getRoles:'pretty' }}</div>
      </div>
    </ng-template>
  </p-organizationChart>

</div>
