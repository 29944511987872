import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '@fi/app/ui';
import { OrganizationChartComponent } from './components/organization-chart/organization-chart.component';
import { NetworkSummaryPageComponent } from './components/summary/summary.component';
import { NetworkPageComponent } from './containers/network-page.component';
import { NETWORK_CONFIG_PROVIDER, NetworkModuleOptions } from './module.options';

const ROUTES: Routes = [
  {
    path: '',
    component: NetworkPageComponent,
    children: [
      {
        path: '',
        redirectTo: 'chart',
        pathMatch: 'full',
      },
      {
        path: '',
        children: [{ path: 'summary', component: NetworkSummaryPageComponent }],
      },
      {
        path: 'chart',
        component: OrganizationChartComponent,
      },
    ],
  },
];
@NgModule({
  declarations: [NetworkPageComponent],
  imports: [CommonModule, RouterModule.forChild(ROUTES), UiModule],
  providers: [],
  exports: [],
})
export class NetworkModule {
  static forRoot(config: NetworkModuleOptions): ModuleWithProviders<NetworkModule> {
    return {
      ngModule: NetworkModule,
      providers: [
        {
          provide: NETWORK_CONFIG_PROVIDER,
          useValue: config,
        },
      ],
    };
  }
}
