import { Component, OnInit, ViewChild } from '@angular/core';
import { TreeTable, TreeTableModule } from 'primeng/treetable';
import { TreeNode } from 'primeng/api';
import { NetworkService } from '@fi/app/client';
import { FormsModule } from '@angular/forms';
import { GetRolesPipe } from '../../pipes/utils.pipe';
@Component({
  selector: 'app-network-summary',
  standalone: true,
  imports: [TreeTableModule, FormsModule, GetRolesPipe],
  templateUrl: `./summary.component.html`,
  styles: [``],
})
export class NetworkSummaryPageComponent implements OnInit {
  @ViewChild('tt') tt: TreeTable;
  network: TreeNode[];
  constructor(private networkService: NetworkService) {}

  ngOnInit(): void {
    this.networkService.getNetwork().subscribe((data) => {
      this.network = data;
    });
  }

  search(text: string) {
    this.expandAll(this.network);
    this.tt.filterGlobal(text, 'contains');
  }

  expandAll(e: TreeNode[]) {
    this.expand(e);
  }

  expand(element: TreeNode[]) {
    element.forEach((element) => {
      element.expanded = true;
      this.expand(element.children);
    });
  }
}
