<div class="content">
  <div class="flex justify-content-end align-items-center">
    <i class="pi pi-search"></i>
    <div class="p-input-icon-left">
      <input ngModel (ngModelChange)="search($event)" />
    </div>
  </div>

  <p-treeTable #tt [value]="network" [globalFilterFields]="['userObj.lastName']">
    <ng-template pTemplate="header">
      <tr>
        <th>Utente</th>
        <th>Ruolo</th>
        <th>Città</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr>
        <td>
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
          {{ rowData.userObj?.firstName }} {{ rowData.userObj?.lastName }}
        </td>
        <td>{{ rowData.userObj | getRoles }}</td>
        <td>{{ rowData.address?.locality }}</td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>
