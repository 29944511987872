import { CommonModule } from '@angular/common';
import { Component, type OnInit } from '@angular/core';
import { NetworkService } from '@fi/app/client';
import { TreeNode } from 'primeng/api';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { GetRolesColorPipe, GetRolesPipe } from '../../pipes/utils.pipe';

@Component({
  selector: 'app-organization-chart',
  standalone: true,
  imports: [
    CommonModule,OrganizationChartModule, GetRolesPipe, GetRolesColorPipe
  ],
  templateUrl: './organization-chart.component.html',
})
export class OrganizationChartComponent implements OnInit {

  network: TreeNode[];
  selectedNodes!: TreeNode[];

  constructor(private networkService: NetworkService) {}

  ngOnInit(): void {
    this.networkService.getNetwork().subscribe((data) => {
      this.network = data;
    });
  }

}
