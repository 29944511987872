import { Inject, Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from '../../../core/app-config';
import { FiRoles, IUser } from '@fi/app/core';
import { NETWORK_CONFIG_PROVIDER, NetworkModuleOptions } from '../module.options';

@Pipe({ name: 'getRoles', standalone: true })
export class GetRolesPipe implements PipeTransform {
  constructor(
    private config: AppConfigService,
    @Inject(NETWORK_CONFIG_PROVIDER) private options: NetworkModuleOptions,
  ) {}

  transform(user: IUser, isPretty?: string) {
    if (user && user.registrations) {
      const reg = user.registrations.find((r) => r.applicationId == this.config.getApplicationId());
      if (this.options?.labels) {
        const roles = reg.roles.filter((r) => r.startsWith('is_'));
        return roles.map((r) => this.options.labels[r]).filter((r) => !!r);
      }
      if (reg) {
        let roles = reg.roles.filter((r) => r.startsWith('is_'));
        if (isPretty) {
          //solo ruoli aziendali
          roles = roles.filter((r) =>
            [
              FiRoles.IS_STORE_MANAGER,
              FiRoles.IS_DIRETTORE_COMMERCIALE,
              FiRoles.IS_AREA_MANAGER,
              FiRoles.IS_AGENTE_JUNIOR,
              FiRoles.IS_REGIONAL_MANAGER,
              FiRoles.IS_AGENTE_SENIOR,
              FiRoles.IS_AGENTE_NEW,
            ].includes(<FiRoles>r),
          );
        }
        roles = roles.map((r) => {
          r = r.replace('is_', '');
          r = r.replace('_', ' ');
          r = r.toLocaleUpperCase();
          return r;
        });

        return roles.join(', ');
      }
    }
  }
}
@Pipe({ name: 'getRolesColor', standalone: true , pure : false})
export class GetRolesColorPipe implements PipeTransform {
  constructor(
    private config: AppConfigService,
    @Inject(NETWORK_CONFIG_PROVIDER) private options: NetworkModuleOptions,
  ) {}

  transform(user: IUser) {
    if (user && user.registrations) {
      const reg = user.registrations.find((r) => r.applicationId == this.config.getApplicationId());
      if (this.options?.labels) {
        const roles = reg.roles.filter((r) => r.startsWith('is_'));
        return roles.map((r) => this.options.labels[r]).filter((r) => !!r);
      }
      if (reg) {
        let roles = reg.roles.filter((r) => r.startsWith('is_'));
          //solo ruoli aziendali
          roles = roles.filter((r) =>
            [
              FiRoles.IS_STORE_MANAGER,
              FiRoles.IS_DIRETTORE_COMMERCIALE,
              FiRoles.IS_AREA_MANAGER,
              FiRoles.IS_AGENTE_JUNIOR,
              FiRoles.IS_REGIONAL_MANAGER,
              FiRoles.IS_AGENTE_SENIOR,
              FiRoles.IS_AGENTE_NEW,
            ].includes(<FiRoles>r),
          );
          if(roles.includes(FiRoles.IS_DIRETTORE_COMMERCIALE)) {
            return 'text-primary'
          }
          if(roles.includes(FiRoles.IS_AREA_MANAGER)) {
            return 'text-blue-500'
          }
          if(roles.includes(FiRoles.IS_REGIONAL_MANAGER)) {
            return 'text-amber-500'
          }
          if(roles.includes(FiRoles.IS_STORE_MANAGER)) {
            return 'text-red-500'
          }

        }


      }

  }
}
